/*@font-face {
        font-family: "Advent Pro";
        src: url("../fonts/AdventPro/AdventPro-Regular.ttf");
    }*/

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Light.ttf");
}

/** {
        font-family: "Roboto" !important;
    }*/

:root {
  /**/
  --ion-color-soft-blue: #e0f7fa;
  --ion-color-soft-blue-rgb: 224, 247, 250;
  --ion-color-soft-blue-contrast: #000000;
  --ion-color-soft-blue-contrast-rgb: 0, 0, 0;
  --ion-color-soft-blue-shade: #c5d9dc;
  --ion-color-soft-blue-tint: #e3f8fb;
  /**/
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 254, 254, 254;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #ffffff;
  --ion-color-white-tint: #ffffff;
  /**/
  --ion-color-tag-grey: #d2d2d2;
  --ion-color-tag-grey-rgb: 210, 210, 210;
  --ion-color-tag-grey-contrast: #000000;
  --ion-color-tag-grey-contrast-rgb: 0, 0, 0;
  --ion-color-tag-grey-shade: #b9b9b9;
  --ion-color-tag-grey-tint: #d7d7d7;
  /**/
  --ion-color-blandy-blue: #4fc3f7;
  --ion-color-blandy-blue-rgb: 79, 195, 247;
  --ion-color-blandy-blue-contrast: #000000;
  --ion-color-blandy-blue-contrast-rgb: 0, 0, 0;
  --ion-color-blandy-blue-shade: #46acd9;
  --ion-color-blandy-blue-tint: #61c9f8;
  /**/
  --inner-border-width: 0px 0px 0px 0px !important;
  /*--ion-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', sans-serif;*/
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-soft-blue {
  --ion-color-base: var(--ion-color-soft-blue);
  --ion-color-base-rgb: var(--ion-color-soft-blue-rgb);
  --ion-color-contrast: var(--ion-color-soft-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-soft-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-soft-blue-shade);
  --ion-color-tint: var(--ion-color-soft-blue-tint);
}

.ion-color-tag-grey {
  --ion-color-base: var(--ion-color-tag-grey);
  --ion-color-base-rgb: var(--ion-color-tag-grey-rgb);
  --ion-color-contrast: var(--ion-color-tag-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tag-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-tag-grey-shade);
  --ion-color-tint: var(--ion-color-tag-grey-tint);
}

.ion-color-blandy-blue {
  --ion-color-base: var(--ion-color-blandy-blue);
  --ion-color-base-rgb: var(--ion-color-blandy-blue-rgb);
  --ion-color-contrast: var(--ion-color-blandy-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blandy-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blandy-blue-shade);
  --ion-color-tint: var(--ion-color-blandy-blue-tint);
}
/*LEAFLET DESIGN*/

.leaflet-control-attribution {
  display: none;
}
/* ********************************************************* */

.roboto {
  font-family: "Roboto" !important;
}
/**/
/* SPLASH */

.splash-label {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--ion-color-blandy-blue);
  margin-bottom: 0.5em;
}

.progress,
.progress-indeterminate {
  background: var(--ion-color-soft-blue);
}
/* FOOTER */

.tab-footer {
  background-color: #f8f8f8;
  text-align: center;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25) !important;
}

.hob-submenu-icon,
.cursor-pointer,
.menu-button,
.back {
  cursor: pointer;
}

.loginForm {
  width: 100%;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: -30;
  height: 100%;
  width: 100%;
  overflow: "hidden";
}
/* SUBMENU */

.menuIcon {
  --size: 7em;
  margin: 0 15%;
  min-height: 120px;
}

/*BUTTONS*/

.button-inner {
  font-weight: bold !important;
  --box-shadow: none !important;
}

.button-native {
  padding: 26px;
  --box-shadow: none !important;
}

.backButton {
  text-shadow: 0px 0px;
  --box-shadow: none;
  color: white;
  background-color: white !important;
}
/* SOME GENERIC STYLES*/

.no-scroll {
  --overflow: hidden;
  --overflow-y: hidden; /* Hide vertical scrollbar */
  --overflow-x: hidden; /* Hide horizontal scrollbar */
}

.bold {
  font-weight: bold !important;
}

.button-inner {
  font-weight: bold !important;
  --box-shadow: none !important;
}

.hob-card {
  width: 100%;
  height: 100vh !important;
  margin: 0;
  padding: 0;
  border-radius: 0px !important;
}

a.mapboxgl-ctrl-logo {
  display: none !important;
}

.hob-card > ion-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
  border-radius: 0px !important;
}

.hob-card > ion-card-content > ion-textarea {
  text-align: left !important;
  padding-left: 10px;
  border-radius: 0px !important;
}

.route-title {
  width: 100%;
  font-size: 1.5rem;
  font-style: italic;
}

.select-interface-option {
  --inner-border-width: 0;
}
/*BOAT*/

.hob-equipment {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.equipment-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-equipment {
  padding-left: 5% !important;
}

.cercle-shape {
  --border-radius: 50%;
  padding: 2px;
  border: 3px solid black;
}

.overview-thumb {
  --border-radius: 50%;
}

.resalted-thumb {
  border: 3px solid #4fc3f7;
}

.overview-container {
  display: flex;
  justify-content: space-between;
}

.overview-thumb-col {
  margin: 0 5px 0 5px;
}

.boat-box {
  width: 100%;
  height: 96vw;
  background: #ccc;
  overflow: hidden;
}

.boat-box img {
  width: 100%;
  height: 100vw;
}

.picture-box {
  width: 100%;
  height: 100vw;
  background: #ccc;
}

.picture-box img {
  width: 100%;
  height: 100%;
}

@supports (object-fit: cover) {
  .boat-box img {
    height: 100vw;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.hob-slide-text {
  margin-top: 10px !important;
}

.hob-slide-textarea {
  padding: 0% 3%;
  text-align: left;
  font-size: 1.25rem;
  overflow: scroll;
  margin-top: 7%;
  height: 20vh;
}

#routes-grid {
  padding: 0px;
}

.place-textarea {
  padding: 0% 2%;
  text-align: left;
  font-size: 1.25rem;
  overflow: scroll;
  margin-top: 7%;
  width: 100%;
}

#header-title-container {
  padding: 3.5% 0 2.5% 0;
  margin: 0.5vh 2% !important;
  font-size: 1.3rem;
}

#header-subtitle-label {
  font-size: 1rem !important;
}

.ion-sel-14-lbl {
  font-size: 1.4rem !important;
}

.groc {
  background-color: #fffdf4 !important;
}

.soft-grey {
  background-color: #ababab !important;
}
/*IMAGE BOX*/

.box {
  width: 100%;
  height: 100vw;
  background: #ccc;
  overflow: hidden;
  border-radius: 0px !important;
}

.box img {
  width: 100%;
  height: 100vw;
  border-radius: 0px !important;
}

.ken-burns {
  animation: move 40s ease;
}

@supports (object-fit: cover) {
  .box img {
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.numTag {
  border-radius: 50vh;
  background-color: lightgrey;
  width: 60px !important;
  padding: 5px !important;
  text-align: center;
}

.route-selector-container {
  width: 100% !important;
  border-radius: 10px;
  --inner-border-width: 0;
}

.route-selector {
  width: 100% !important;
  border-radius: 10px;
}

.item-interactive {
  --border-width: 0 0 0 0;
}

.back {
  margin-right: 2%;
  max-width: 25px;
}

.left {
  text-align: left;
}

.equipment-box {
  margin-top: 5px;
  width: 100%;
}
/* EFFECTS */

.blink-slowest {
  animation: blinker 6s linear infinite;
}

.blink-slow {
  animation: blinker 4s linear infinite;
}

.blink-fast {
  animation: blinker 2s linear infinite;
}

.icon,
.leaflet-marker-icon {
  min-height: 25px;
  animation: blinker 3s linear infinite;
}

.monger {
  animation: monger 4s linear infinite;
}

@keyframes blinker {
  60% {
    opacity: 0.4;
  }
}

@keyframes monger {
  50% {
    margin: 4%;
  }
}

@keyframes donger {
  50% {
    margin-top: 2%;
    opacity: 0.5;
  }
  100% {
    margin-top: -0%;
    opacity: 1;
  }
}

.fade-in-2 {
  animation: fadeIn 0.2s;
  -webkit-animation: fadeIn 0.2s;
  -moz-animation: fadeIn 0.2s;
  -o-animation: fadeIn 0.2s;
  -ms-animation: fadeIn 0.2s;
}

.fade-in-5 {
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes move {
  0% {
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
    transform: scale(1);
    -ms-transform: scale(1);
    /* IE 9 */

    -webkit-transform: scale(1);
    /* Safari and Chrome */

    -o-transform: scale(1);
    /* Opera */

    -moz-transform: scale(1);
    /* Firefox */
  }
  100% {
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    /* IE 9 */

    -webkit-transform: scale(1.2);
    /* Safari and Chrome */

    -o-transform: scale(1.2);
    /* Opera */

    -moz-transform: scale(1.2);
    /* Firefox */
  }
}

.slider-pill {
  position: absolute;
  background-color: #4e4d7e55 !important;
  top: 2%;
  right: 3vw;
  font-size: 1rem;
  padding: 0.9% 2.2%;
  border-radius: 30px;
  color: #272626 !important;
  font-weight: bold;
  opacity: 0.7;
  z-index: 5;
}

.white-text {
  color: #ffffff !important;
}

.greyscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.5;
}

.background {
  background-color: "#9d9fa6";
}

.videoWrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.videoWrapper div {
  top: 0px;
  left: 0px;
  z-index: 1;
  box-sizing: border-box;
  border: 6px solid;
  border-color: var(--ion-color-blandy-blue);
  box-shadow: var(--ion-color-blandy-blue) 0px 0px 0px 5px inset;
  position: absolute;
  width: 100%;
  height: 100%;
}

.videoWrapper video {
  top: 0px;
  left: 0px;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: blinker 4s linear infinite;
}






.center {
	margin: 0 auto;
	text-align: center;
}

.spacer {
	margin-top: 6rem;
}

/* barcode container */
.anim-box {
	position: relative;
	width: 220px;
	height: 100px;
	padding: 25px 30px;
	transition: transform .6s ease-out;
}

/* adds the 4 corners */
.anim-box:before, .anim-box:after, .anim-box>:first-child:before, .anim-box>:first-child:after {
	position: absolute;
	width: 10%; height: 15%;
	border-color: white; 
	border-style: solid;
	content: ' ';
}

/* top left corner */
.anim-box:before {
	top: 0; left: 0;
	border-width: 2px 0 0 2px;
}

/* top right corner */
.anim-box:after {
	top: 0; right: 0;
	border-width: 2px 2px 0 0;
}

/* bottom right corner */
.anim-box>:first-child:before {
	bottom: 0; right: 0;
	border-width: 0 2px 2px 0;
}

/* bottom left corner */
.anim-box>:first-child:after {
	bottom: 0; left: 0;
	border-width: 0 0 2px 2px;
}

/* barcode bars */
.anim-item {
	display: inline-block;
	background-color: white;
	height: 100px;
}

.anim-item-sm {
	width: 2px;
	margin-right: 3px;
}

.anim-item-md {
	width: 3px;
	margin-right: 2px;
}

.anim-item-lg {
	width: 5px;
	margin-right: 5px;
}

/* grow on hover */
.anim-box:hover {
	transform: scale(1.5, 2);
}

.anim-box:hover .scanner {
  animation-play-state: running;
}

/* animated laser beam */
.scanner {
	width: 100%;
	height: 3px;
	background-color: red;
  opacity: 0.7;
  position:relative;
  box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
  top:50%;
  animation-name: scan;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: paused;
}

@keyframes scan {
  0% {
    box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
    top: 50%;
  }  
  25% {
    box-shadow: 0px 6px 8px 10px rgba(170, 11, 23, 0.49);
    top: 5px;
  }
  75% {
    box-shadow: 0px -6px 8px 10px rgba(170, 11, 23, 0.49);
    top: 98%;
  }
}


.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 3px !important;
}
/*SWIPER*/

.swiper-pagination {
  top: 102vw!important;
}

.swiper-pagination-bullet {
  background: #3171e0;
  height: 8px;
  width: 8px;
}

.swiper-pagination-bullets {
  height: 8px;
  width: 8px;
}

.swiper-pagination-bullet-active {
  background: #8bf6ff;
  height: 11px;
  width: 11px;
}
